
import note1 from '../assets/imgs/note1.png';
import note2 from '../assets/imgs/note2.png';
import note3 from '../assets/imgs/note3.png';
import note4 from '../assets/imgs/note4.png';
import faq1 from '../assets/imgs/faq1.png';
import faq2 from '../assets/imgs/faq2.png';
import faq3 from '../assets/imgs/faq3.png';
import note1Mobile from '../assets/imgs/mobile/note1.png';
import note2Mobile from '../assets/imgs/mobile/note2.png';
import note3Mobile from '../assets/imgs/mobile/note3.png';
import note4Mobile from '../assets/imgs/mobile/note4.png';
import faq1Mobile from '../assets/imgs/mobile/faq1.png';
import faq2Mobile from '../assets/imgs/mobile/faq2Mobile.png';
import faq3Mobile from '../assets/imgs/mobile/faq3.png';
export const page1 = [
  {
    img: note1,
    mobImg: note1Mobile,
    icon: '#iconzu5',
    title: 'MÁS RÁPIDO',
    description: '1 RUN/RUT, 1 número de móvil, el préstamo emitido en 2 a 24 horas',
  },
  {
    img: note2,
    mobImg: note2Mobile,
    icon: '#iconzu2',
    title: 'MÁS SEGURO',
    description: 'Información personal cifrada para garantizar la seguridad, sólo utilizada para a revisión y certificación',
  },
  {
    img: note3,
    mobImg: note3Mobile,
    icon: '#iconzu4',
    title: 'MÁS FÁCIL',
    description: 'Múltiples fuentes, tan fácil como comprar una cola',
  },
  {
    img: note4,
    mobImg: note4Mobile,
    icon: '#iconzu3',
    title: 'MÁS COMO UN AMIGO',
    description: 'Un equipo fuerte siempre está listo para servirle cuando encuentre dificultades',
  },
];

export const page3 = [
  {
    img: faq1,
    mobImg: faq1Mobile,
    title: '¿Qué cualificaciones se requieren？',
    descriptions: [
      '1) Un número de teléfono en uso',
      '2) Identificación gubernamental válida',
      '3) Ingresos estables',
      '4) Edad entre 18 y 49 años'
    ],
  },
  {
    img: faq2,
    mobImg: faq2Mobile,
    title: '¿Cómo solicitar un préstamo?',
    descriptions: [
      '1) Instale ListoCash y regístrese con su propio número de teléfono.',
      '2) Rellene el formulario de solicitud de préstamo en CLP.',
      '3) Espere la aprobación del préstamo en efectivo.',
      '4) Reclame su préstamo personal.'
    ],
  },
  {
    img: faq3,
    mobImg: faq3Mobile,
    title: '¿Cómo se hace el reembolso?',
    descriptions: [
      '1) Banca móvil, banca web pago en línea - fácil y rápido',
      '2) Agentes, agentes para el reembolso fuera de línea - proceso simple',
    ]
  },
];
