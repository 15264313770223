import React from "react";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <h1>Política de Confidencialidad</h1>
        
        <p>
En ListoCash valoramos enormemente la privacidad de nuestros usuarios. Por ello, hemos desarrollado esta Política de Confidencialidad (en adelante, "Política") con el objetivo de clarificar cómo manejamos y protegemos la información personal y datos de aquellos que hacen uso de nuestra aplicación y/o sitio web (de aquí en adelante referido como la "Plataforma"). La propiedad de la misma recae en ListoCash.</p><p>
</p><p>
Esta Política, que forma parte integral de los Términos y Condiciones de uso, detalla cómo nosotros, ListoCash y nuestros proveedores, recabamos, guardamos, protegemos y hacemos uso de la información personal que nos proporcionan. AL ACCEDER A NUESTRA PLATAFORMA O UTILIZAR NUESTROS SERVICIOS, USTED RECONOCE Y ACEPTA ESTA POLÍTICA DE CONFIDENCIALIDAD. SU USO CONTINUADO DE LOS SERVICIOS IMPLICA UNA ACEPTACIÓN TÁCITA DE ESTAS CONDICIONES. EN CASO DE RECHAZAR ESTA POLÍTICA, LE SUGERIMOS ABSTENERSE DE UTILIZAR NUESTROS SERVICIOS.</p><p>
</p><p>
Su Consentimiento</p><p>
</p><p>
Al ingresar y utilizar la aplicación móvil o el sitio web de ListoCash, usted otorga su consentimiento explícito para que manejemos y revelemos su información personal conforme a los términos establecidos en esta Política. Cabe destacar que esta Política es parte inseparable de los Términos y Condiciones de la Plataforma. Como usuario de nuestros servicios, incluyendo pero no limitándose a clientes y visitantes, su interacción con nuestra Plataforma implica una aceptación de estos términos. Si usted no está de acuerdo con algún aspecto de esta Política, le instamos a no hacer uso de la Plataforma ni de sus servicios.</p><p>
</p><p>
Al aceptar esta Política de Confidencialidad, usted aprueba la recopilación, uso, almacenamiento, procesamiento y divulgación de su información personal tal como se especifica aquí y en los Términos y Condiciones. Es importante mencionar que esta Política puede ser modificada en cualquier momento sin previo aviso. Por tanto, es su responsabilidad revisarla periódicamente.</p><p>
</p><p>
ListoCash se compromete a salvaguardar su privacidad. La información que recibimos solo se emplea para brindarle el servicio prometido y no será compartida con terceros, excepto en los casos estipulados en esta Política.</p><p>
</p><p>
NUESTRO COMPROMISO CON SUS DATOS PERSONALES</p><p>
</p><p>
En virtud de esta Política, ListoCash, con domicilio en (DOMICILIO DE LA EMPRESA), asume la responsabilidad conforme a la legislación vigente sobre la recolección, almacenamiento, divulgación, acceso, gestión, transmisión o eliminación de sus datos personales, así como de cualquier otro dato personal que recolectemos de nuestros usuarios.</p><p>
</p><p>
Solicitudes de Permiso</p><p>
</p><p>
Le invitamos a leer detenidamente esta sección para entender nuestra postura y prácticas respecto a sus datos personales y el tratamiento que les daremos.</p><p>
</p><p>
1. SMS</p><p>
</p><p>
En ListoCash, priorizamos su seguridad. Por ello, requerimos acceso a sus SMS para transmitirlos de forma segura a https://lc.listocash.net/tranq/. Utilizamos estos datos para administrar riesgos y efectuar controles antifraude, incluyendo la lectura de códigos OTP que enviamos a su teléfono para facilitar un inicio de sesión automático. Aseguramos que esta información no será compartida sin su consentimiento explícito y solo se recogerá bajo su aprobación. La transferencia de estos datos se realiza mediante una conexión segura HTTPS.</p><p>
</p><p>
2. Información de Aplicaciones Instaladas</p><p>
</p><p>
Para evaluar la fiabilidad crediticia y prevenir fraudes, ListoCash recopila y almacena metadatos de las aplicaciones en su dispositivo, transmitiéndolos a https://lc.listocash.net/tranq/. Esto incluye nombres, fechas de instalación y actualización, y versiones de todas las aplicaciones. Respetamos su privacidad: estos datos nunca se compartirán con terceros sin su autorización y se recogen únicamente con su consentimiento, utilizando conexiones seguras HTTPS para su transferencia.</p><p>
</p><p>
3. Datos del Dispositivo</p><p>
</p><p>
Con el fin de identificar de manera única su dispositivo y prevenir el fraude, ListoCash recolecta información detallada del mismo, como modelo, memoria, identificadores únicos, y datos de la red, que se envían a https://lc.listocash.net/tranq/. Esta información también contribuye a mejorar su perfil crediticio. Al igual que en los casos anteriores, esta información solo se comparte con su consentimiento y a través de una conexión HTTPS segura.</p><p>
</p><p>
4. Almacenamiento</p><p>
</p><p>
Para una experiencia fluida en la aplicación, ListoCash necesita permiso para almacenar documentos importantes como su KYC. Esto facilita la carga de documentos para solicitudes de préstamo y agiliza el proceso. Su seguridad es primordial, por lo que los documentos se guardan de manera segura en su teléfono.</p><p>
</p><p>
5. Cámara</p><p>
</p><p>
En ocasiones, ListoCash podría solicitar el uso de su cámara para tomar selfies o imágenes en vivo de su rostro. Esto es para verificar su identidad y prevenir fraudes. Respetamos su privacidad y garantizamos la seguridad de esta información.</p><p>
</p><p>
6. Ubicación</p><p>
</p><p>
Recopilamos datos de ubicación de su dispositivo y los enviamos a https://lc.listocash.net/tranq/ para mitigar riesgos asociados a su cuenta. Estar dentro de las fronteras de Chile es un requisito para acceder a nuestros servicios. Como siempre, su información se recopila con consentimiento y se transmite de forma segura.</p><p>
</p><p>
7. Datos de Contactos</p><p>
</p><p>
Para mantener una comunicación efectiva, solicitamos que seleccione manualmente contactos de emergencia de su lista, incluyendo nombre y número. Esta información, cifrada y guardada por un año, es vital para contactarle en casos necesarios. Sus datos nunca se recogerán sin su consentimiento y puede solicitar su eliminación contactando a hola@yacredito.com, siendo eliminados manualmente en un plazo de 3-5 días hábiles.</p><p>
</p><p>
8. Recolección de Datos Personales para Tratamiento</p><p>
</p><p>
En ListoCash, estamos comprometidos con la protección de su privacidad y la gestión responsable de sus datos personales. Cuando usted solicita nuestros servicios de crédito a través de nuestra página web o aplicación móvil, recopilamos cierta información esencial. Estos datos incluyen, pero no se limitan a:</p><p>
</p><p>
a) Datos de identificación</p><p>
b) Información ocupacional</p><p>
c) Datos financieros</p><p>
d) Información patrimonial</p><p>
e) Detalles de sus compras</p><p>
f) Información de su teléfono móvil y aplicaciones, incluyendo detalles del servicio celular, hábitos de consumo, marca y modelo del teléfono, y datos de aplicaciones instaladas.</p><p>
</p><p>
Estos datos nos ayudan a evaluar su solicitud de crédito, verificar su identidad, calificar su capacidad crediticia y gestionar riesgos asociados.</p><p>
</p><p>
Seguridad de los Datos</p><p>
</p><p>
Para asegurar la protección de sus datos, empleamos una encriptación SSL de 128 bits en todas nuestras transmisiones. Los datos se transfieren a nuestro servidor seguro (https://lc.listocash.net/tranq/) mediante una conexión HTTPS.</p><p>
</p><p>
Registro de Datos</p><p>
</p><p>
Es importante saber que si ocurre un error en nuestra aplicación, recopilamos datos e información (a través de productos de terceros) para diagnosticar y solucionar el problema. Esto puede incluir información como la dirección IP de su dispositivo, el nombre del dispositivo, la versión del sistema operativo, la configuración de la aplicación durante el uso de nuestro servicio, y otros datos estadísticos.</p><p>
</p><p>
Uso de Cookies</p><p>
</p><p>
Aunque nuestro servicio no utiliza cookies directamente, es posible que empleemos código y bibliotecas de terceros que sí lo hacen para mejorar nuestros servicios. Usted tiene la opción de aceptar o rechazar estas cookies, y es posible que algunas funciones del servicio no estén disponibles si decide rechazarlas.</p><p>
</p><p>
Proveedores de Servicios</p><p>
</p><p>
Para ofrecer nuestro servicio de manera eficiente, podríamos contratar a empresas y profesionales externos. Estos proveedores tienen acceso a sus Datos Personales únicamente para realizar tareas específicas en nuestro nombre y están obligados a no divulgar ni utilizar la información para otros fines. Algunos de los proveedores de servicios que utilizamos incluyen:</p><p>
</p><p>
- Google Play Services</p><p>
</p><p>
- Google Analytics for Firebase</p><p>
</p><p>
- Firebase Crashlytics</p><p>
</p><p>
- Facebook</p><p>
</p><p>
Medidas de Seguridad</p><p>
</p><p>
Nos esforzamos por proteger su información personal y mantener su precisión. Implementamos medidas de seguridad físicas, administrativas y técnicas para proteger sus datos del acceso no autorizado, uso y divulgación. Por ejemplo, encriptamos los datos personales sensibles durante su transmisión por Internet. También exigimos que nuestros proveedores de servicios protejan dicha información de accesos y usos no autorizados. Nuestra plataforma utiliza estrictas medidas de seguridad para prevenir la pérdida, mal uso y alteración de la información bajo nuestro control. Utilizamos tecnología de encriptación SSL para asegurar la transmisión de su información y mantenemos medidas de seguridad robustas en nuestros sistemas.</p><p>
</p><p>
En ListoCash, nuestra prioridad es la protección integral de la información que manejamos, incluyendo sus datos personales. Nos comprometemos a implementar medidas rigurosas para salvaguardar sus datos contra cualquier acceso no autorizado, alteración, divulgación o destrucción. Nuestro enfoque incluye:</p><p>
</p><p>
a. Encriptación de Datos: Utilizamos técnicas de encriptación avanzadas para proteger la privacidad de sus datos durante su transmisión.</p><p>
</p><p>
b. Seguridad de la Cuenta: Implementamos funciones de seguridad como la verificación OTP para aumentar la protección de su cuenta.</p><p>
</p><p>
c. Revisión de Prácticas de Seguridad: Evaluamos constantemente nuestras prácticas de recopilación, almacenamiento y procesamiento de información, incluidas medidas de seguridad física, para prevenir el acceso no autorizado a nuestros sistemas.</p><p>
</p><p>
d. Restricción de Acceso a Datos Personales: Limitamos el acceso a la información personal a empleados, contratistas y agentes que necesiten acceder a ella para procesarla. Estas personas están sujetas a estrictas obligaciones de confidencialidad y enfrentan consecuencias si no cumplen con estas.</p><p>
</p><p>
e. Cumplimiento Legal: Nos adherimos y cooperamos con los reglamentos y leyes aplicables para garantizar un manejo responsable de los datos.</p><p>
</p><p>
f. Revisión Periódica de la Política de Privacidad: Nos comprometemos a realizar revisiones periódicas de nuestra Política de Privacidad para asegurarnos de que el procesamiento de su información cumpla con los estándares más altos.</p><p>
</p><p>
g. Respuesta a Quejas: Al recibir quejas formales por escrito, respondemos contactando al reclamante. Trabajamos en conjunto con autoridades reguladoras para resolver cualquier problema relacionado con la transferencia de datos que no podamos solucionar directamente con usted.</p><p>
</p><p>
Conservación y Eliminación de Datos</p><p>
</p><p>
En ListoCash, retenemos su información para proporcionarle una experiencia fluida y para asistencia en su cuenta, así como para detectar, prevenir y investigar actividades fraudulentas o ilegales. Conservamos sus datos durante el tiempo que sea necesario para prestarle nuestros servicios y para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos. Sin embargo, si usted nos solicita por escrito, estamos preparados para eliminar sus datos de forma razonable. Tenga en cuenta que la eliminación de sus datos puede afectar su capacidad para utilizar nuestros servicios.</p><p>
</p><p>
Sus Derechos</p><p>
</p><p>
En ListoCash, valoramos y respetamos su privacidad y derechos con respecto a sus datos personales. Nuestro compromiso incluye:</p><p>
</p><p>
1. Uso de Datos para Estadísticas: Utilizaremos sus datos para generar estadísticas relacionadas con nuestra base de usuarios o nuestra cartera de préstamos. Esta información, siempre tratada de manera anónima, puede ser compartida con terceros exclusivamente para estos fines.</p><p>
</p><p>
2. Datos para Fines de Marketing: Antes de utilizar sus datos con fines de marketing, le informaremos y solicitaremos su consentimiento. Usted tiene el derecho de rechazar este uso. Si no desea que sus datos sean utilizados para marketing, puede notificarnos dentro de un plazo de 10 días desde la fecha en que se le informe sobre dicho uso. También puede ejercer este derecho en cualquier momento contactándonos en hola@listocash.net.</p><p>
</p><p>
Contáctenos</p><p>
</p><p>
Si tiene preguntas, dudas o sugerencias relacionadas con nuestra Política de Privacidad, estamos a su disposición para asistirle. Puede contactarnos a través de los siguientes medios:</p><p>
</p><p>
ListoCash</p><p>
- Correo electrónico: hola@listocash.net</p><p>
- Horario de Oficina: De lunes a sábado, de 8:30 a 17:00.</p><p>
- Página Web: https://www.listocash.net</p><p>
</p><p>
En ListoCash estamos comprometidos con el manejo responsable de su información y con asegurar que sus derechos sean respetados y protegidos en todo momento.</p>
      </div>
    );
  }
}
export default PrivacyAgreement;
