import React from 'react';
// import QueueAnim from 'rc-queue-anim';
// import { Row } from 'antd';
import { page3 } from './data';
import title2 from '../assets/imgs/title2.png'

// import { HashRouter as Router, Link } from "react-router-dom";

export default function Page3(props) {
  const renderDesc = (descriptions) => {
    const descHtml= descriptions.map((item, i) => (
      <p key={i.toString()}>
        {item}
      </p>
    ));
    return descHtml;
  };
  const children = page3.map((item, i) => (
    <div className="one-card" key={i.toString()}>
      <img src={props.isMobile ? item.mobImg : item.img} className={i === 1 ? "cardImg" : null} alt="" />
      <div className={['common-card', i === 1 ? 'common-card1' : 'common-card2'].join(' ')} >
        <p className="title">{item.title}</p>
        <div className="detail">{renderDesc(item.descriptions)}</div>
      </div>
    </div>
  ));

  return (
    <section id="FAQ" className={'block3'+(props.isMobile? '-mobile': '')}>
      <div>
            <div className={['topTip'].join(' ')}>
            <img alt="FAQ" className='title' src={title2}/>
                <div>El equipo de ListoCash ha compilado una lista de dudas comunes que esperan que le ayuden.</div>
            </div>
            {children}
          </div>
    </section>
  );
}
