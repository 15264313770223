import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Terminos de Uso" />
        <h1>TERMINOS DE USO </h1>
        <p>
</p><p>
1. INTRODUCCIÓN</p><p>
</p><p>
Este documento establece los términos y condiciones ("Términos") que aplican a quienes accedan al sitio web ("Sitio Web") y/o aplicación ("App") de ListoCash.</p><p>
</p><p>
También debe revisar nuestra Política de Privacidad en https://www.listocash.net/politicaAcuerdo, donde detallamos cómo tratamos su información personal. Nos preocupamos por mantener seguros a nuestros usuarios y trabajamos constantemente para manejar su información de manera confidencial.</p><p>
</p><p>
Al utilizar el Sitio Web y/o la App, acepta sin reservas todas las disposiciones de estos Términos, los cuales son vinculantes y obligatorios según el Artículo 141 y 141-A del Código Civil. Si no está de acuerdo, debe abstenerse de acceder y usar el Sitio Web, la App y nuestros servicios.  </p><p>
</p><p>
En caso de incumplimiento, ListoCash podrá suspender o negar su acceso al sistema, sin previo aviso, y presentar las acciones legales correspondientes, proporcionando la información necesaria para investigar y perseguir cualquier conducta ilícita.</p><p>
</p><p>
Se considerará infracción el cometer actos abusivos, inapropiados, ilegales, fraudulentos o que constituyan conductas prohibidas.</p><p>
</p><p>
2. DEFINICIONES</p><p>
</p><p>
Estos términos con mayúscula inicial tendrán el siguiente significado:</p><p>
</p><p>
● "Afiliados": Cualquier entidad que controla, es controlada o está bajo control común con una parte.  </p><p>
</p><p>
● "Servicio": Cualquier servicio o producto financiero que ListoCash pueda ofrecerle.</p><p>
</p><p>
● "Cuenta": Su cuenta individual para acceder al sistema y utilizar el servicio.</p><p>
</p><p>
● "App": Aplicación electrónica de ListoCash para acceder al sistema y servicio.  </p><p>
</p><p>
● "Sitio Web": Cualquier sitio web operado por ListoCash.</p><p>
</p><p>
● "Usuario": Persona natural o jurídica que utiliza los servicios de ListoCash.</p><p>
</p><p>
● "Leyes Vigentes": Todas las leyes, códigos, normas, resoluciones, tratados aplicables en Chile.</p><p>
</p><p>
● "Credenciales": Sus datos personales para acceder al Sitio Web, App y operar su Cuenta.</p><p>
</p><p>
● "Central de Riesgo": Empresa que proporciona información sobre historial crediticio.</p><p>
</p><p>
● "Historial Crediticio": Informe de su comportamiento como receptor de préstamos.</p><p>
</p><p>
● "Información Confidencial": Cualquier dato confidencial o propiedad de ListoCash.</p><p>
</p><p>
● "Préstamo": Monto del préstamo otorgado por ListoCash al Usuario.</p><p>
</p><p>
● "Dispositivo Móvil": Teléfono móvil, tarjeta SIM u otro equipo para acceder a la red y App.</p><p>
</p><p>
● "Operador de Red Móvil": Proveedor de servicio de telefonía móvil registrado en Chile. </p><p>
</p><p>
● "Cuenta Bancaria": Producto financiero para depositar y administrar dinero.</p><p>
</p><p>
● "Entidad Bancaria": Institución que custodia el dinero y otorga préstamos.</p><p>
</p><p>
● "Tarjeta Bancaria": Medio de pago emitido por un banco asociado a una cuenta.</p><p>
</p><p>
● "Información Personal": Datos que permiten identificar a un individuo.</p><p>
</p><p>
● "Solicitud": Instrucción recibida del Usuario a través del sistema.</p><p>
</p><p>
● "Tarjeta SIM": Módulo para acceder a la red móvil desde un teléfono.  </p><p>
</p><p>
● "SMS": Servicio de mensajes cortos de texto.</p><p>
</p><p>
● "Sistema": Sistema de ListoCash para proveer el Servicio.</p><p>
</p><p>
● "Territorio": República de Chile.</p><p>
</p><p>
● "Tarifas de Transacción": Tarifas por el uso del Servicio según se publique.</p><p>
</p><p>
● "Cookies": Archivos de datos almacenados en el dispositivo del Usuario.</p><p>
</p><p>
3. RESPONSABILIDADES DEL USUARIO</p><p>
</p><p>
El Usuario confirma y se compromete con ListoCash a: </p><p>
</p><p>
3.1 Tener poder y autoridad para aceptar estos Términos.</p><p>
3.2 Cumplir las Leyes Vigentes y estos Términos, notificando cualquier incumplimiento.</p><p>
3.3 Utilizar el Sistema y Servicio sólo para fines legales y propósitos destinados.</p><p>
3.4 Asegurar que los documentos, Información Personal y Credenciales proporcionados sean precisos, actuales, completos y no engañosos.</p><p>
3.5 Utilizar solo una Cuenta autorizada.</p><p>
3.6 No participar en conductas fraudulentas o engañosas.</p><p>
3.7 No perjudicar ni eludir el funcionamiento adecuado de la red del Sistema.</p><p>
</p><p>
4. ACEPTACIÓN DE LOS TÉRMINOS</p><p>
</p><p>
4.1 El Usuario declara haber leído y comprendido estos Términos antes de descargar la App o registrar una Cuenta.</p><p>
4.2 Al hacer clic en "Aceptar", confirma haber leído, entendido y aceptado cumplir con estos Términos.</p><p>
4.3 Al descargar la App y registrar una Cuenta, acepta cumplir estos Términos que rigen su funcionamiento.</p><p>
4.4 ListoCash puede modificar estos Términos periódicamente. Su uso continuado del Servicio constituye la aceptación de las modificaciones.</p><p>
4.5 Puede requerirse descargar la última versión de la App y aceptar nuevos Términos.</p><p>
4.6 Al usar la App, autoriza a ListoCash a recopilar y usar datos técnicos para determinar su calificación crediticia y mejorar el Servicio.  </p><p>
4.7 Autoriza expresamente a ListoCash a realizar investigaciones sobre su comportamiento crediticio e historial en las Centrales de Riesgo que estime conveniente por 3 años o mientras mantenga relación jurídica.</p><p>
4.8 También autoriza a contactarlo y a su contacto de emergencia para verificar su Información Personal o comunicarse sobre pagos adeudados.</p><p>
4.9 Autoriza a ListoCash a usar su Tarjeta Bancaria para deducción automática.</p><p>
</p><p>
5. USO DEL SERVICIO</p><p>
</p><p>
5.1 El Servicio sólo puede ser utilizado por mayores de 18 años.</p><p>
5.2 Reconoce que la aceptación de su Cuenta no crea relación contractual con Entidades Bancarias más allá de los términos de su Cuenta Bancaria.</p><p>
5.3 ListoCash se reserva el derecho de rechazar o revocar su Solicitud de Préstamo sin expresar motivos.</p><p>
5.4 Nos reservamos el derecho de emitir, rechazar o variar los términos de cualquier Préstamo según nuestra evaluación de su perfil crediticio.</p><p>
</p><p>
6. USO DEL SISTEMA </p><p>
  </p><p>
Derechos otorgados y reservados</p><p>
</p><p>
6.1 Sujeto al cumplimiento de estos Términos, ListoCash le otorga una licencia revocable, limitada, no exclusiva, intransferible y sin regalías para acceder y usar el Sistema únicamente con el fin de adquirir el Servicio durante la vigencia de estos Términos en el Territorio.</p><p>
6.2 Todos los derechos no otorgados expresamente están reservados por ListoCash. Nada en estos Términos le transfiere la propiedad del Sistema.</p><p>
</p><p>
Conductas prohibidas </p><p>
</p><p>
6.3 Al usar el Sistema, no deberá:</p><p>
</p><p>
6.3.1 Licenciar, sublicenciar, vender, revender, transferir, distribuir o explotar comercialmente el Sistema.</p><p>
6.3.2 Modificar, realizar ingeniería inversa o acceder al software subyacente.  </p><p>
6.3.3 Usar el Sistema para construir un producto o servicio competitivo, copiar ideas, características o funciones, o lanzar scripts automatizados que puedan sobrecargar indebidamente el Sistema.</p><p>
6.3.4 Usar aplicaciones para recuperar, indexar, "extraer datos" o reproducir la estructura de navegación o contenido del Sistema.</p><p>
6.3.5 Publicar, distribuir o reproducir material con derechos de autor, marcas registradas u otra propiedad sin autorización.</p><p>
6.3.6 Enviar o almacenar material con fines ilegales o fraudulentos.</p><p>
6.3.7 Enviar spam u otros mensajes no solicitados, causar molestias o realizar reservas falsas.</p><p>
6.3.8 Enviar o almacenar material infractor, obsceno, amenazante, difamatorio o ilegal.</p><p>
6.3.9 Enviar material que contenga virus, gusanos, troyanos u otro código dañino.</p><p>
6.3.10 Interferir o alterar la integridad o rendimiento del Sistema o sus datos.</p><p>
6.3.11 Hacerse pasar por otra persona o entidad o tergiversar su afiliación.</p><p>
6.3.12 Tergiversar deliberadamente su ubicación.</p><p>
6.3.13 Tergiversar Información Personal importante.</p><p>
6.3.14 Dañar nuestra reputación o la de nuestras compañías.</p><p>
6.3.15 Recopilar o intentar descifrar transmisiones hacia o desde nuestros servidores.</p><p>
</p><p>
7. SU CUENTA</p><p>
</p><p>
7.1 Para acceder al Sistema como Usuario debe registrar y mantener una Cuenta.</p><p>
</p><p>
7.2 Es responsable de todas las actividades en su Cuenta, por lo tanto:</p><p>
</p><p>
7.2.1 Solo debe tener una Cuenta.</p><p>
7.2.2 Debe mantener confidencial y segura la Información Personal de su Cuenta.</p><p>
7.2.3 No debe proporcionar acceso a su Cuenta a ninguna otra persona.</p><p>
7.2.4 Debe notificarnos de inmediato sobre cualquier uso o acceso no autorizado.</p><p>
</p><p>
7.3 Nos reservamos el derecho de bloquear o denegar el acceso a su Cuenta y/o funciones de la App, sin perjuicio de otros derechos y recursos, si:</p><p>
</p><p>
7.3.1 Considera que ha violado estos Términos.</p><p>
7.3.2 Durante una investigación.</p><p>
7.3.3 Nos debe capital, intereses, tarifas o impuestos.</p><p>
7.3.4 Estos Términos son rescindidos.</p><p>
7.3.5 A nuestra discreción razonable.</p><p>
</p><p>
8. SU INFORMACIÓN PERSONAL</p><p>
</p><p>
Acepta y consiente que usemos y procesemos su Información Personal según la Política de Privacidad https://www.listocash.net/politicaAcuerdo.</p><p>
</p><p>
9. SOLICITUDES DEL USUARIO</p><p>
</p><p>
9.1 Nos autoriza irrevocablemente a actuar sobre todas las Solicitudes que recibamos (o aparenten ser) del Usuario a través del Sistema y responsabilizarlo por ellas.</p><p>
9.2 Nos reservamos el derecho de rechazar cualquier Solicitud de Préstamo, incluso si previamente le emitimos uno.</p><p>
9.3 Tendremos derecho a aceptar Solicitudes incompletas o ambiguas si, a nuestro criterio, creemos poder corregir la información sin consultarlo.</p><p>
9.4 Se considerará que actuamos correctamente en Solicitudes iniciadas por error o fraudulentamente, y estará obligado por las Solicitudes en las que actuemos de buena fe creyendo que las envió.</p><p>
9.5 Podremos negarnos a actuar total o parcialmente sobre su Solicitud en espera de su confirmación adicional.</p><p>
9.6 Acepta liberarnos e indemnizarnos de todos los reclamos, pérdidas, daños, costos y gastos resultantes de haber actuado de acuerdo con sus Solicitudes (o de no ejercer la discreción conferida).</p><p>
9.7 No seremos responsables por sorteos, transferencias, divulgaciones, actividades o incidentes no autorizados en su Cuenta por el conocimiento y/o uso no autorizado de sus Credenciales, ocasionado por su negligencia.</p><p>
9.8 Estamos autorizados a ejecutar órdenes respecto a su Cuenta requeridas por orden judicial, autoridad o agencia competente según las Leyes Vigentes.</p><p>
9.9 En caso de conflicto, prevalecerán estos Términos sobre cualquier Solicitud recibida.</p><p>
</p><p>
10. MEDIOS ELECTRÓNICOS DE AUTENTICACIÓN</p><p>
</p><p>
Acepta que su consentimiento expreso podrá otorgarse mediante un "aceptar" en una "casilla de selección" a través de medios electrónicos, incluida la App, o cualquier otro medio contemplado en la legislación aplicable.</p><p>
</p><p>
11. RESPONSABILIDADES DEL USUARIO</p><p>
</p><p>
11.1 Deberá proporcionar y mantener en orden y funcionamiento seguro su Dispositivo Móvil para acceder al Sistema y Servicio.  </p><p>
11.2 Será responsable de garantizar el rendimiento adecuado de su Dispositivo Móvil. No seremos responsables por errores o fallas causados por mal funcionamiento, virus informáticos o problemas relacionados.</p><p>
11.3 El acceso a la App será a través de su Dispositivo Móvil. Es su responsabilidad verificar y descargar la App correcta. </p><p>
11.4 Si su Dispositivo Móvil se pierde, es robado o roto, exponiendo la Información Personal de su Cuenta, debe notificarnos de inmediato. No seremos responsables por la divulgación de dicha información a terceros y usted indemnizará cualquier pérdida resultante.</p><p>
11.5 Es el único responsable de contar con un plan móvil e Internet adecuado y cualquier tarifa cobrada por su Operador de Red Móvil.  </p><p>
11.6 Deberá seguir todas las instrucciones, procedimientos y términos provistos por ListoCash sobre el uso del Sistema y Servicio.</p><p>
11.7 Deberá tomar precauciones razonables para detectar uso no autorizado del Sistema y Servicio. En ese sentido, revisará y verificará nuestras comunicaciones para detectar accesos no autorizados e informarnos de inmediato si:</p><p>
</p><p>
11.7.1 Cree que sus Credenciales fueron o pudieron ser conocidas por terceros no autorizados.</p><p>
11.7.2 Cree que ha ocurrido o puede ocurrir uso no autorizado y una transacción pudo haber sido ingresada o comprometida fraudulentamente.</p><p>
11.7.3 Deberá seguir los procedimientos de seguridad que le notifiquemos periódicamente o cualquier otro aplicable al Servicio. Reconoce que el incumplimiento de los procedimientos de seguridad puede resultar en una violación de confidencialidad de su Cuenta.</p><p>
</p><p>
12. TÉRMINOS FINANCIEROS </p><p>
</p><p>
12.1 ListoCash mostrará en la App los intereses pagaderos por cualquier Préstamo. Tendremos derecho a establecer y cobrar Tarifas de Transacción por el uso del Servicio, pudiendo modificarlas periódicamente. Cualquier cambio en las Tarifas se mostrará en la App con razonable anticipación.</p><p>
</p><p>
12.2 Si no realiza los pagos adeudados en la fecha de vencimiento, estaremos autorizados a aplicar cargos por demora sobre el monto prestado a la tasa que se muestre en la App.</p><p>
</p><p>
Impuestos</p><p>
</p><p>
12.3 Todos los pagos que deba realizar se calculan sin considerar impuestos que deba pagar. Si se paga algún impuesto, debe pagarnos un monto adicional igual al pago multiplicado por la tasa impositiva apropiada al momento de realizar el pago o cuando lo solicitemos, incluso después de finalizada la relación.  </p><p>
</p><p>
12.4 Acepta que podemos retener cantidades en su Cuenta si alguna autoridad fiscal nos exige hacerlo, si la ley o acuerdos con autoridades fiscales nos lo requieren, o si debemos cumplir con nuestras políticas internas o cualquier orden o sanción de una autoridad fiscal.</p><p>
</p><p>
Pagos</p><p>
</p><p>
12.5 El principal, intereses, tarifas e impuestos relacionados con estos Términos y el Préstamo deben pagarse mediante los métodos de pago ofrecidos y mostrados en la App antes/en la fecha de vencimiento.</p><p>
</p><p>
12.6 Todos los pagos deben realizarse en la moneda local del Territorio y a través de las Entidades Bancarias. </p><p>
</p><p>
12.7 Acepta que todos los reembolsos/pagos deben realizarse a la Cuenta designada según el proceso de pago instruido por la App de ListoCash, de lo contrario, no podremos recibir su pago y tendremos derecho a solicitar que pague el saldo restante. Si paga a una Cuenta no indicada, asumirá toda la responsabilidad sin involucrar a la App.</p><p>
</p><p>
13. INCUMPLIMIENTO</p><p>
</p><p>
13.1 Ocurre un evento de incumplimiento cuando el Usuario:</p><p>
</p><p>
13.1.1 No paga cualquier suma o cuota (incluidos intereses, tarifas e impuestos) de un Préstamo por 15 días acumulativos, a menos que sea por un error administrativo o problema técnico.</p><p>
13.1.2 Se declara en quiebra.</p><p>
</p><p>
13.2 En cualquier momento después de un evento de incumplimiento que continúe, podremos, sin perjuicio de otros derechos o recursos:</p><p>
</p><p>
13.2.1 Rescindir estos Términos según la Cláusula 14.</p><p>
13.2.2 Declarar que el Préstamo (incluidos intereses, tarifas, impuestos y montos pendientes) se vence y se paga inmediatamente.  </p><p>
13.2.3 Proporcionar información del incumplimiento a las Centrales de Riesgo.</p><p>
13.2.4 Imponer cargos por pagos atrasados a la tasa que se muestre en la App.</p><p>
</p><p>
14. PLAZO Y RESCISIÓN  </p><p>
</p><p>
14.1 Estos Términos continuarán hasta su finalización según sus términos.</p><p>
14.2 Podremos rescindir estos Términos y/o suspender o rescindir su uso del Sistema, Servicio y Cuenta total o parcialmente:</p><p>
</p><p>
14.2.1 En cualquier momento por cualquier motivo mediante notificación.</p><p>
14.2.2 Inmediatamente, con o sin previo aviso, si incumple cualquiera de estos Términos.</p><p>
14.2.3 Si su Cuenta o acuerdo con un Operador de Red Móvil o Entidad Bancaria se rescinde. </p><p>
14.2.4 Cuando sea necesario por problemas técnicos, de seguridad, para facilitar actualizaciones del Servicio, o si su Cuenta se vuelve inactiva.</p><p>
14.2.5 Si se nos exige cumplir con una orden o instrucción gubernamental, judicial o de otra autoridad competente.</p><p>
14.2.6 Si decidimos suspender la provisión del Servicio por razones comerciales o cualquier otra razón.</p><p>
</p><p>
14.3 Al término de estos Términos por cualquier motivo, deberá:</p><p>
</p><p>
14.3.1 Pagar puntualmente el principal, intereses, tarifas o impuestos adeudados (los cuales serán pagaderos inmediatamente al finalizar).</p><p>
14.3.2 Eliminar inmediata y completamente la App de su Dispositivo Móvil.</p><p>
</p><p>
14.4 Sin embargo, la rescisión no afectará los derechos y responsabilidades acumulados de ninguna parte.</p><p>
14.5 Las partes no tendrán más obligaciones o derechos después de la terminación, sin perjuicio de las obligaciones o derechos acumulados al momento de la terminación, excepto las disposiciones de las Cláusulas 2, 3, 8, 11, 13, 14, 15 y 16 que continuarán vigentes.</p><p>
</p><p>
15. INDEMNIZACIONES Y EXCLUSIÓN DE RESPONSABILIDAD</p><p>
</p><p>
Indemnizaciones</p><p>
</p><p>
15.1 Deberá defender, indemnizar y eximir de responsabilidad a ListoCash, nuestros licenciantes, Afiliados y sus respectivos funcionarios, directores, miembros, empleados y agentes de y contra todos los reclamos, costos, daños, pérdidas, responsabilidades y gastos (incluidos honorarios de abogados) que surjan de o en relación con:</p><p>
</p><p>
15.2.1 Su incumplimiento de cualquier término de estos Términos o cualquier Ley Vigente.</p><p>
15.2.2 Su uso del Sistema y/o Servicio, incluyendo:</p><p>
15.2.2.1 Cualquier reclamo de terceros derivado de su uso.</p><p>
15.2.2.2 Cualquier pérdida o daño derivado del uso, mal uso o abuso de cualquier software de terceros.</p><p>
15.2.2.3 Cualquier acceso no autorizado a su Cuenta o violación de seguridad, destrucción, robo o daño a sus datos o Dispositivo Móvil.</p><p>
15.2.2.4 Cualquier pérdida o daño ocasionado por incumplimiento de estos Términos y/o proporcionar Información Personal incorrecta.</p><p>
15.2.2.5 Cualquier pérdida o daño ocasionado por falla o falta de disponibilidad de instalaciones o sistemas de terceros o la incapacidad de una tercera parte para procesar una transacción.</p><p>
</p><p>
Exclusión de responsabilidad</p><p>
</p><p>
15.3 No seremos responsables de ninguna pérdida sufrida por interferencia o no disponibilidad del Servicio debido a falla de su Dispositivo Móvil o cualquier circunstancia fuera de nuestro control razonable, incluyendo fuerza mayor, error, interrupción, demora o falta de disponibilidad del Sistema, falla de equipos, acción terrorista o de enemigos, pérdida de energía, condiciones climáticas adversas y falla de cualquier sistema de telecomunicaciones público o privado.</p><p>
</p><p>
15.4 Reconoce que la App no ha sido desarrollada para cumplir con sus requisitos individuales y que es su responsabilidad asegurarse de que las funciones descritas cumplan con sus requisitos.</p><p>
</p><p>
15.5 Proveemos la App solo para uso privado. Acepta no utilizarla para fines comerciales, reventa, y no tendremos responsabilidad por pérdida de ganancias, interrupción de negocios o pérdida de oportunidades comerciales.</p><p>
</p><p>
15.6 No seremos responsables de ninguna pérdida o daño sufrido como resultado de:</p><p>
</p><p>
15.6.1 Cualquier defecto o falla en la App o Servicio resultante de haber alterado o modificado la App.</p><p>
15.6.2 Cualquier defecto o falla resultante de usar la App en violación de estos Términos.</p><p>
15.6.3 Su incumplimiento de la Cláusula 6.</p><p>
15.6.4 Indisponibilidad de fondos suficientes en su Cuenta Bancaria.  </p><p>
15.6.5 Falla, mal funcionamiento, interrupción o indisponibilidad del sistema, Dispositivo Móvil, red o sistema de su Cuenta Bancaria.</p><p>
15.6.6 Cualquier uso fraudulento o ilegal del Servicio, Sistema y/o Dispositivo Móvil.</p><p>
15.6.7 Su incumplimiento de estos Términos y cualquier documento o Información Confidencial sobre el uso del Sistema y Servicio.</p><p>
</p><p>
15.7 Bajo ninguna circunstancia seremos responsables ante usted por pérdida de ganancias o ahorros anticipados o por cualquier pérdida o daño indirecto o consecuencial relacionado con el Servicio.</p><p>
</p><p>
15.8 Nuestra máxima responsabilidad total relacionada con la App, Sistema, Servicio y/o estos Términos no excederá, en la máxima medida permitida por la Ley Aplicable, las tarifas de transacción pagadas por el primer evento que dio lugar a un reclamo.</p><p>
</p><p>
15.9 A menos que se especifique lo contrario, cualquier reclamo contra ListoCash relacionado con la App, Sistema, Servicio o estos Términos debe notificarse dentro de los 6 meses posteriores a los eventos que dieron lugar al reclamo o perderá derechos y recursos sobre dicho reclamo.</p><p>
</p><p>
15.10 No asumimos responsabilidad por:</p><p>
</p><p>
15.10.1 Mal funcionamiento de instalaciones de comunicaciones fuera de nuestro control razonable que puedan afectar la precisión o puntualidad de mensajes o material al que acceda a través de la App.</p><p>
15.10.2 Cualquier pérdida o demora en la transmisión de mensajes o material causada por proveedores de acceso a Internet, red móvil o navegadores u otro software fuera de nuestro control.</p><p>
15.10.3 Virus que puedan infectar su Dispositivo Móvil u otra propiedad debido al acceso o uso de la App/Sitio Web.</p><p>
15.10.4 Cualquier uso o intercepción no autorizada de mensajes o Información Personal antes de que lleguen a la App o nuestros servidores.  </p><p>
15.10.5 Cualquier uso o acceso no autorizado a datos relacionados con usted o sus transacciones que tengamos (a menos que sea causado por nuestra negligencia, fraude o incumplimiento de Leyes Vigentes de protección de datos).</p><p>
15.10.6 Cualquier contenido proporcionado por terceros.</p><p>
</p><p>
16. SITIOS DE TERCEROS</p><p>
</p><p>
16.1 Podemos establecer en el Sitio Web o App enlaces a otros sitios web o aplicaciones móviles operados por terceros ("Sitios o Apps de Terceros"). Estos enlaces se proporcionan solo como referencia informativa, pero el establecimiento de cualquier enlace no constituye recomendación o aprobación de cualquier producto, servicio, información, ideas u opiniones que puedan encontrarse en dichos Sitios o Apps.</p><p>
</p><p>
16.2 No otorgamos ninguna garantía respecto al contenido de los Sitios o Apps de Terceros, incluida su precisión, integridad, confiabilidad o idoneidad para cualquier propósito. No garantizamos que estén libres de reclamos de derechos de autor, marcas registradas u otra infracción. No garantizamos que estén libres de virus u otra contaminación.  </p><p>
</p><p>
16.3 Entiende que los Sitios y Apps de Terceros pueden tener una política de privacidad diferente a la nuestra y pueden proporcionar menos seguridad. La decisión de acceder a un Sitio o App de Terceros o utilizar cualquiera de sus productos o servicios es bajo su propio riesgo.</p><p>
</p><p>
17. CONSENTIMIENTO PARA MARKETING DIRECTO</p><p>
</p><p>
Al usar los Servicios, acepta recibir nuestras comunicaciones de marketing directo. Si no desea recibirlas, puede expresar su elección donde se indique.</p><p>
</p><p>
18. PROPIEDAD INTELECTUAL</p><p>
</p><p>
El software, contenido, Información Confidencial y material disponible en el Sitio Web y/o App/Sistema son propiedad de ListoCash y/o sus servidores externos, protegidos por las Leyes Vigentes en materia de derechos de autor, marcas, patentes, modelos y diseños industriales aplicables en Chile. Todos los derechos no otorgados expresamente están reservados por ListoCash y/o sus servidores externos.</p><p>
</p><p>
Queda prohibida cualquier copia, distribución, transmisión, retransmisión, publicación, impresión, difusión y/o explotación comercial del material o contenido, sin el consentimiento expreso y por escrito de ListoCash. Cualquier violación constituirá una infracción a los derechos de propiedad intelectual y sujetará al infractor a las sanciones administrativas, civiles y penales correspondientes, obligándose a indemnizar y liberar de responsabilidad a ListoCash.</p><p>
</p><p>
18. PROPIEDAD INTELECTUAL</p><p>
</p><p>
El software, contenidos, información confidencial y material disponible en nuestro sitio web, aplicación y sistema son propiedad de ListoCash y/o sus proveedores externos. Están protegidos por las leyes chilenas de derechos de autor, marcas, patentes, modelos y diseños industriales, estén o no registrados. ListoCash y sus proveedores se reservan todos los derechos no otorgados expresamente. </p><p>
</p><p>
Está totalmente prohibido copiar, distribuir, transmitir, publicar, imprimir, difundir o explotar comercialmente cualquier contenido de nuestra plataforma sin autorización previa y por escrito de ListoCash. Quien infrinja estos derechos estará sujeto a sanciones legales y deberá indemnizarnos por cualquier reclamo derivado de su incumplimiento.</p><p>
</p><p>
19. COOKIES</p><p>
</p><p>
Al ingresar a nuestro sitio web y aplicación, utilizamos cookies, que son pequeños archivos almacenados en tu dispositivo para rastrear la actividad y mejorar tu experiencia. La mayoría de navegadores y dispositivos móviles aceptan cookies, pero puedes ajustar la configuración para rechazarlas o eliminarlas. Sin embargo, esto puede afectar algunas funcionalidades.</p><p>
</p><p>
20. VALIDEZ DE LOS TÉRMINOS </p><p>
</p><p>
Si alguna disposición de estos términos es considerada inválida, no afectará la validez del resto. Dicha disposición será separada y reformulada de forma válida y consistente con la voluntad de las partes según lo originalmente redactado, en la máxima medida posible permitida por la ley.</p><p>
</p><p>
21. LEGISLACIÓN Y JURISDICCIÓN APLICABLES</p><p>
</p><p>
Estos términos se rigen por las leyes chilenas. Cualquier controversia derivada se someterá a la jurisdicción de los tribunales competentes de la ciudad de Santiago de Chile, renunciando a cualquier otra jurisdicción.</p><p>
</p><p>
22. PROTECCIÓN DE DATOS </p><p>
</p><p>
ListoCash implementará todas las medidas para mantener confidencial y segura tu información personal, pero no responderá por daños derivados de la violación de estas medidas por terceros que accedan indebidamente a dicha información.  </p><p>
</p><p>
23. GENERALIDADES</p><p>
</p><p>
23.1 No seremos responsables por demoras o fallas fuera de nuestro control razonable.</p><p>
23.2 No debes divulgar información confidencial de ListoCash, sus afiliados, clientes o proveedores.</p><p>
23.3 Podemos transferir derechos del préstamo sin notificarte. Esto no afectará tus obligaciones de pago según las instrucciones en la app.</p><p>
23.4 Podremos modificar estos términos. Haremos esfuerzos razonables por notificarte cambios importantes, pero es tu responsabilidad revisarlos periódicamente. Tu uso continuado implicará aceptación de las modificaciones.</p><p>
23.5 Nuestros derechos son acumulativos, pueden ejercerse las veces necesarias y sólo renunciarse por escrito.</p><p>
23.6 Estos términos constituyen el acuerdo completo entre las partes sobre la materia y reemplazan cualquier acuerdo previo.</p><p>
23.7 No puedes ceder tus derechos y obligaciones sin nuestro consentimiento previo por escrito. Nosotros sí podremos hacerlo.</p><p>
23.8 Si un tribunal declara alguna parte inválida, el resto mantendrá su vigencia y la parte inválida será reemplazada por una disposición legal equivalente. </p><p>
23.9 Sólo las partes pueden exigir el cumplimiento de estos términos.</p><p>
</p><p>
24. NOTIFICACIONES</p><p>
</p><p>
Cualquier notificación, queja, sugerencia o comentario se enviará por correo electrónico a: hola@listocash.net (ListoCash) o la dirección registrada en nuestro sitio/app (Usuario). Las notificaciones se entenderán recibidas al día siguiente de su envío.</p><p>
</p><p>
25. CONSENTIMIENTO </p><p>
</p><p>
Al utilizar nuestros servicios, aceptas haber leído, comprendido y consentido estos Términos y Condiciones, Política de Privacidad y demás documentos de ListoCash.</p><p>
</p><p>
Última actualización: 20/06/2021</p>
        <p></p>
      </div>
    );
  }
}
export default ServiceAgreement;
