import React, { Component } from 'react';
import Home from './Home';
import Politica from './Page/politicaAcuerdo';
import Terminos from './Page/terminosAcuerdo';
import Eliminar from './Page/cancelLaSolicitud';
import ErrorInfo from './Page/ErrorInfo';
import { Router, Route } from "react-router-dom";
import {createBrowserHistory} from 'history'
const browserHistory = createBrowserHistory()
class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route exact path="/politicaAcuerdo" component={Politica} />
        <Route exact path="/terminosAcuerdo" component={Terminos} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/cancelacion" component={Eliminar} />

      </Router>
    );
  }
}

export default App;
